import styled from '@emotion/styled';
import { faCircleUser, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid, Stack } from '@mui/material';
import React from 'react';
import FbIcon from '../img/facebook.png';
import IgIcon from '../img/instagram.png';
import TwitterIcon from '../img/twitter.png';
import LdIcon from "../img/linkedin.png";
import CTypography from './custom/CTypography';

const DividerStyle = styled(Divider)(({ theme }) => ({
    margin: '0px 20px 0px 20px',
    opacity: '30%',
    backgroundColor: 'white'
}));

const IconStyle = styled(Grid)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: 36,
    height: 36,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderRadius: 999,
    marginRight: 12
}));

const SocialIconStyle = styled('img')(({ theme }) => ({
    width: 36,
    height: 36,
}))

const Footer = () => {
    return (


        <Grid container direction="column" sx={{ backgroundColor: "#f0f0f0" }}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={3} sx={{ p: 5, mt: 2 }} >
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent="center" direction="column" wrap='nowrap' >
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <IconStyle item >
                                        <FontAwesomeIcon style={{ width: 18, height: 18, margin: 7 }} icon={faLocationDot} />
                                    </IconStyle>
                                    <Grid item>
                                        <CTypography variant="h6" fontWeight='bold' color='primary'>Address</CTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <CTypography sx={{ mt: 2, fontSize: 14 }}>Argon IT Services LLP<br />304,3rd Floor,Oasis Corner,<br />Dhanmora Char Rasta,Katargam,<br />Surat,Gujarat-395004</CTypography>
                            </Grid>
                        </Grid >
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent="center" direction="column" wrap='nowrap'>
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <IconStyle item>
                                        <FontAwesomeIcon style={{ width: 18, height: 18, margin: 7 }} icon={faPhone} />
                                    </IconStyle>
                                    <Grid item>
                                        <CTypography variant="h6" color='primary' fontWeight='bold'>Phone</CTypography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <CTypography sx={{ mt: 2, fontSize: 14 }}>+91-9723724025</CTypography>
                                <CTypography sx={{ fontSize: 14 }}>9:00 am - 7:00 pm</CTypography>
                            </Grid>
                        </Grid >
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent="center" direction="column" wrap='nowrap'>
                            <Grid item>
                                <Grid container alignItems='center' direction="row">
                                    <IconStyle item >
                                        <FontAwesomeIcon style={{ width: 18, height: 18, margin: 7 }} icon={faEnvelope} color="primary" />
                                    </IconStyle>
                                    <Grid item>
                                        <CTypography variant="h6" fontWeight='bold' color='primary'> Mail</CTypography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <CTypography sx={{ mt: 2, fontSize: 14 }}>info@argonitservices.com</CTypography>
                                <CTypography sx={{ fontSize: 14 }}>24 X 7 online support</CTypography>
                            </Grid >
                        </Grid >
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container justifyContent="center" direction="column" wrap='nowrap'>
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <IconStyle item >
                                        <FontAwesomeIcon style={{ width: 18, height: 18, margin: 7 }} icon={faCircleUser} />
                                    </IconStyle>
                                    <Grid item>
                                        <CTypography variant="h6" fontWeight='bold' color='primary'>Social</CTypography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
                                    <a href="https://www.facebook.com/profile.php?id=100081676174463" target='_blank'>
                                    <SocialIconStyle src={FbIcon} alt="" />
                                    </a>
                                    <a target='_blank' href="https://www.instagram.com/argon_it_services_llp/?igshid=MzRlODBiNWFlZA%3D%3D">
                                    <SocialIconStyle src={IgIcon} alt="" />
                                    </a>
                                    <a target='_blank' href="https://twitter.com/InfotechArgon">
                                    <SocialIconStyle src={TwitterIcon} alt="" />
                                    </a>
                                    <a target='_blank' href="https://www.linkedin.com/company/argonitservicesllp/">
                                    <SocialIconStyle src={LdIcon} alt="" />
                                    </a>
                                </Stack>
                            </Grid>
                        </Grid >
                    </Grid>
                </Grid>
            </Grid>
            <Grid item >
                <DividerStyle />
            </Grid>
            <Grid item sx={{ p: 1 }}>
                <CTypography align='center' variant='body1' sx={{ fontSize: 12 }}>
                    Copyright © {(new Date().getFullYear())}. All rights reserved.
                </CTypography>
            </Grid>
        </Grid>




    );
}

export default Footer;