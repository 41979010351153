import { withTheme } from '@emotion/react';
import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Parse } from 'parse';
import React from "react";
import history from "../history";
import careerImage from '../img/career.webp';
import { validateEmail, validateLastName, validateName } from "../utills/Validation";
import { ApplicantTable } from './../database/ApplicantTable';
import { Links } from './../utills/Links';
import { validateMobile } from './../utills/Validation';
import CustomSnack from './custom/customSnack';
import CTypography from './custom/CTypography';
import { APP_BAR_HEIGHT } from './Navbar';
import Hiringboard from '../img/Hiring_bord.png'
import  LoadingButton  from '@mui/lab/LoadingButton';


const InputStyle = styled('input')(() => ({
    display: 'none'
}));

const CGridItemStyle = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${careerImage})`,
    marginBottom: 18,

    width: '100%',
    minHeight: '30vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}));

const Career = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const MAX_FILE_SIZE = 5000000

    const [firstName, setFirstName] = React.useState("")
    const [errorFirstName, setErrorFirstName] = React.useState(false);
    const [helperFirstName, setHelperFirstName] = React.useState("");

    const [lastName, setLastName] = React.useState("")
    const [errorLastName, setErrorLastName] = React.useState(false);
    const [helperLastName, setHelperLastName] = React.useState("");

    const [email, setEmail] = React.useState("")
    const [errorEmail, setErrorEmail] = React.useState(false);
    const [helperEmail, setHelperEmail] = React.useState("");

    const [mobile, setMobile] = React.useState("")
    const [errorMobile, setErrorMobile] = React.useState(false);
    const [helperMobile, setHelperMobile] = React.useState("");

    const [applyFor, setApplyFor] = React.useState("")
    const [expYear, setExpYear] = React.useState("")
    const [education, setEducation] = React.useState("")
    const [resumeFile, setResumeFile] = React.useState(null)
    const [fileName, setFileName] = React.useState("")
    const [details, setDetails] = React.useState("")

    const [snackContent, setSnackContent] = React.useState();
    const [variant, setVariant] = React.useState("error");

    const [isLoading, setIsLoading] = React.useState(false);

    const handleOnChange = (e) => {
        e.preventDefault();
        if (e.target.id === "tf-first-name") {
            setFirstName(e.target.value)
            setErrorFirstName(false)
            setHelperFirstName("")
        } else if (e.target.id === "tf-last-name") {
            setLastName(e.target.value)
            setErrorLastName(false)
            setHelperLastName("")
        } else if (e.target.id === "tf-email") {
            setEmail(e.target.value)
            setErrorEmail(false)
            setHelperEmail("")
        } else if (e.target.id === "tf-mobile") {
            setMobile(e.target.value)
            setErrorMobile(false)
            setHelperMobile("")
        } else if (e.target.id === "tf-apply-for") {
            setApplyFor(e.target.value)
        } else if (e.target.id === "tf-exp-year") {
            setExpYear(e.target.value)
        } else if (e.target.id === "tf-education") {
            setEducation(e.target.value)
        } else if (e.target.id === "tf-details") {
            setDetails(e.target.value)
        }

    }

    const handleFileSelection = (e) => {
        uploadFile(e.target.files[0])
    }

    const uploadFile = (file) => {

        if (file.size > MAX_FILE_SIZE) {
            setSnackContent("File size cannot be greater than 5MB");
            setVariant('error')
            return
        }

        var parseFile = new Parse.File(`argonInfoTech${(new Date()).toString().replace(/\W/g, '')}`, file);
        parseFile.save().then(function () {
            // The file has been saved to Parse.

            setResumeFile(parseFile)
            setFileName(file.name)
        }, function (error) {
            setSnackContent("Error while uploading file")
            setVariant('error')
            // The file either could not be read, or could not be saved to Parse.
        });

    }

    const handleOnSubmit = (e) => {
        e.preventDefault()

        if (!validateEmail(email)) {
            setHelperEmail("Invalid email address");
            setErrorEmail(true);
            return
        }
        if (!validateMobile(mobile)) {
            setHelperMobile("Invalid mobile number");
            setErrorMobile(true);
            return
        }
        if (!validateName(firstName)) {
            setHelperFirstName("Minimum two letter required");
            setErrorFirstName(true);
            return
        }
        if (!validateLastName(lastName)) {
            setHelperLastName("Minimum two letter required");
            setErrorLastName(true);
            return
        }
        if (!resumeFile) {
            setSnackContent('Upload valid file')
            setVariant('error')
            return
        }

        const obj = new Parse.Object(ApplicantTable.TABLE_NAME);
        obj.set(ApplicantTable.FIRST_NAME, firstName);
        obj.set(ApplicantTable.LAST_NAME, lastName);
        obj.set(ApplicantTable.EMAIL_ID, email);
        obj.set(ApplicantTable.MOBILE_NO, mobile);
        obj.set(ApplicantTable.APPLY_FOR, applyFor);
        obj.set(ApplicantTable.EXPERIENCE_YEAR, expYear);
        obj.set(ApplicantTable.EDUCATION, education);
        obj.set(ApplicantTable.DETAILS, details);
        obj.set(ApplicantTable.RESUME, resumeFile)

        setIsLoading(true)
        Parse.Object.saveAll(obj).then(res => {
            setIsLoading(false)
            console.log("res" + res.toString())
            setSnackContent("We have received your request. We will contact you shortly.");
            setVariant("success")
            history.push(Links.home)
        })
    }

    return (
        <Grid container style={{ marginTop: APP_BAR_HEIGHT }}>
            <CGridItemStyle item xs={12}>
                <Grid container sx={{ height: '100%', backgroundColor: 'rgba(0,0,0,0.4)' }} >
                    <Grid item xs={6}>
                        <Grid container sx={{ height: '100%' }} alignItems='center' justifyContent='flex-end'>
                            <Grid item>
                                <CTypography variant="h4" sx={{ fontSize: 48, fontWeight: 'bolder', color: 'white' }}>Apply Now</CTypography>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={6}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item xs={12} md={6} lg={4} >
                                <img src={Hiringboard} style={{ width: 100, height: 100 }} alt=" " />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
                {/* <img style={{ objectFit: 'cover', width: "100%", hight: "30vw" }} src={careerImage} alt=" " /> */}
            </CGridItemStyle>
            <Grid item xs={12}>
                <Container>
                    <CustomSnack
                        open={Boolean(snackContent)}
                        onClose={() => { setSnackContent(null) }}
                        message={snackContent}
                        variant={variant} />
                    <form onSubmit={handleOnSubmit}>
                        <Stack sx={{ mt: 5 }}>

                            <Grid container spacing={3} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        id="tf-first-name"
                                        label="First Name"
                                        defaultValue=" "
                                        value={firstName}
                                        error={errorFirstName}
                                        helperText={helperFirstName}
                                        onChange={handleOnChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="tf-last-name"
                                        label="Last Name"
                                        defaultValue=" "
                                        helperText={helperLastName}
                                        error={errorLastName}
                                        value={lastName}
                                        required
                                        onChange={handleOnChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="tf-email"
                                        label="Email Address"
                                        defaultValue=" "
                                        helperText={helperEmail}
                                        error={errorEmail}
                                        value={email}
                                        required
                                        onChange={handleOnChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        id="tf-mobile"
                                        label="Mobile No"
                                        defaultValue=" "
                                        helperText={helperMobile}
                                        error={errorMobile}
                                        value={mobile}
                                        required
                                        onChange={handleOnChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField

                                        fullWidth
                                        id="tf-apply-for"
                                        label="Apply For"
                                        defaultValue=" "
                                        value={applyFor}
                                        onChange={handleOnChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField

                                        fullWidth
                                        id="tf-exp-year"
                                        label="Experience Year"
                                        defaultValue=" "
                                        value={expYear}
                                        onChange={handleOnChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 3 }} >
                                <Grid item xs={12} md={6}>
                                    <TextField

                                        fullWidth
                                        id="tf-education"
                                        label="Education"
                                        defaultValue=" "
                                        value={education}
                                        onChange={handleOnChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center" >
                                            <InputStyle
                                                accept="pdf/*"
                                                id="input-file"
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                type="file"
                                                onChange={handleFileSelection}
                                            />
                                            <label htmlFor="input-file">
                                                <Button variant="outlined" component="span" align="center" color="primary">Choose File</Button>
                                            </label>
                                            {
                                                resumeFile ?
                                                    <Typography variant="body1">{fileName}</Typography>
                                                    :
                                                    <Typography variant="body1"> No file chosen</Typography>

                                            }

                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* <Grid container > */}
                            <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                                <TextField
                                    minRows="4"
                                    multiline
                                    fullWidth
                                    id="tf-details"
                                    label="Details"
                                    defaultValue=" "
                                    value={details}
                                    onChange={handleOnChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack alignItems='flex-end'>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit" size="medium"
                                        sx={{ px: { xs: 2, md: 5 }, py: { xs: 0.5, md: 1 }, borderRadius: 999, color: 'white', fontWeight: 'bold' }} >
                                        SUBMIT
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            {/* <Grid>
                            <Grid item sx={{ mt: 3 }}>
                                <Typography>Alternatively you can send it to  our official email address</Typography>
                            </Grid>
                        </Grid> */}
                            {/* <Button variant="contained" size="small"> SUBMIT </Button> */}
                            {/* </Grid> */}
                        </Stack>
                    </form>
                </Container>
            </Grid>
        </Grid>
    );
}

export default withTheme(Career);