import Parse from 'parse'

//Live server
const APP_ID = "l28mBEaaFR7s6zGRjNFzUC4MtRSejvMd8bN9Lzlr";
const JS_KEY = "89eL8B4QrPH1TjWfiscVbnHZLxFNDchpEMk2ieWC";


//const APP_ID = "maYQ1EJzGpNh0LwLe8nXWPPIM21gmdzgbisaEMPL";
//const JS_KEY = "cnzv7RlB5h92SZLgQGYEhoD8QxAZreC60DaQ4GvO";



export const connectToServer = function () {

    Parse.serverURL = 'https://parseapi.back4app.com/';
    Parse.initialize(APP_ID, JS_KEY);


};