export const ApplicantTable = {
    TABLE_NAME: "Applicant",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL_ID: "emailId",
    MOBILE_NO: "mobileNo",
    APPLY_FOR: "applyFor",
    PROFILE: "profile",
    EXPERIENCE_YEAR: "experienceYear",
    EXPERIENCE_MONTH: "experienceMonth",
    EDUCATION: "education",
    DETAILS: "details",
    RESUME: "resume"

}