import styled from '@emotion/styled';
import { faArrowsToEye, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, CardMedia, Container, Divider, Grid, Hidden, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Slide } from 'react-awesome-reveal';
import A from '../img/a.png';
import AI from '../img/ai.png';
import Android from '../img/android.png';
import Banner from '../img/banner.png';
import MobileBanner from '../img/mobile_banner.png';
import Clients from '../img/clients.png';
import Employees from '../img/employees.png';
import Fig from '../img/fig.png';
import Flutter from '../img/flutter.png';
import GameImage from '../img/game_mobile.png';
import IC from '../img/ic_since.png';
import IOS from '../img/ios.png';
import Line from '../img/line.png';
import MacbookImage from '../img/macbook.png';
import Map from '../img/map2.png';
import IPhoneImage from '../img/mobile.png';
import NOTE from '../img/note_js.png';
import PHP from '../img/php.png';
import Project from '../img/project.png';
import PS from '../img/ps.png';
import RE from '../img/re_js.png';
import UiImage from '../img/tablate.png';
import Unty from '../img/unty.png';
import XD from '../img/xd.png';
import ContactUsCard from './ContactUsCard';
import CTypography from './custom/CTypography';


const GridItemStyle = styled(Grid)(({ theme }) => ({
    marginTop: 18,
    marginBottom: 18,
    [theme.breakpoints.up('md')]: {
        marginTop: 36,
        marginBottom: 36,
    },
}));

const BannerImgStyle = styled('img')({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: "100%",
    objectFit: 'cover',
    position: 'absolute',

});

const BoxStyle = styled(Box)({
    paddingTop: '37.50%',
    position: 'relative',
    display: 'block',
    width: "100%",
    backgroundColor: '#f5f5f5',
})

const XsBannerImgStyle = styled('img')({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: "100%",
    objectFit: 'cover',
    position: 'absolute',

});

const XsBoxStyle = styled(Box)({
    paddingTop: '130%',
    position: 'relative',
    display: 'block',
    width: "100%",
    backgroundColor: '#f5f5f5',


})

// const CGridItemStyle = styled(Grid)(({ theme }) => ({

//     backgroundImage: `url(${Benner})`,
//     marginBottom: 18,

//     width: '100%',
//     minHeight: '70vh',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
// }));

const MapBgStyle = styled(Grid)(({ theme }) => ({
    //backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${Map})`,
    minHeight: '70vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflowX: 'hidden',
}));



const ImgStyle = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
}));

const AvatarStyle = styled('img')(({ theme }) => ({
    width: 128,
    height: 128,
    objectFit: 'cover',
    borderRadius: "50%"
}));

const IMacImageStyle = styled('img')(({ theme }) => ({
    width: '50vw',
    height: '90%',
    objectFit: 'cover',
}));

const CLogoStyle = styled('img')(({ theme }) => ({
    width: 36,
    height: 36
}))

const CImgStyle = styled('img')(({ theme }) => ({
    width: 36,
    height: 36,
    objectFit: 'contain'
}))




const Home = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <Grid container>
            <Hidden mdDown>
                <Grid item xs={12} >
                    <BoxStyle>
                        <BannerImgStyle alt="" src={Banner} />
                    </BoxStyle>
                    {/* <Grid container noWrap justifyContent='space-between' alignItems='center' sx={{ height: '100%' }}>
                    // <Grid item>
                    //     <Stack direction="column" sx={{ height: "100%", ml: [3, 5, 10] }} justifyContent='center' >
                    //         {/* <CTypography variant='h3' sx={{ fontWeight: 'bold' }} color="textPrimary">
                    //             IT Solutions for<br />
                    //             GROWING BUSINESSES
                    //         </CTypography>
                    //         <CTypography variant='h6'>
                    //             Leading Software company in Surat
                    // //         </CTypography> */}
                    {/* //     </Stack> */}
                    {/* // </Grid> */}
                    {/* <Hidden smDown>
                    <Grid item >
                        <Slide direction='right' triggerOnce> */}
                    {/* <img src={Benner} style={{ width: "100%" }} alt=" " /> */}
                    {/* <IMacImageStyle src={IMacImage} /> */}
                    {/* </Slide>
                    </Grid>
                </Hidden> */}
                    {/* // </Grid> */}

                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs={12} >
                    <XsBoxStyle>
                        <XsBannerImgStyle alt="" src={MobileBanner} />
                    </XsBoxStyle>
                </Grid>
            </Hidden>


            <GridItemStyle item xs={12} sx={{ p: 3 }}>

                <Grid container alignItems="center" spacing={[3, 5]} justifyContent="center" sx={{ mt: 2, mb: 2, px: [3, 5] }} >
                    <Grid item xs={6} md={3}>
                        <Card sx={{ borderRadius: '10% 0 10% 0' }} align='center'>

                            <CardMedia sx={{ height: 100, backgroundColor: '#DFF6FF' }} >
                                <Stack alignItems="center" sx={{ height: "100%" }} justifyContent="center" >
                                    <CImgStyle src={IC} alt=" " />

                                </Stack>
                            </CardMedia>
                            <CardContent>
                                <Stack direction="column" alignItems="center" justifyContent="center" >
                                    <CTypography variant="h6" >Since</CTypography>
                                    <Slide direction='up' >
                                        <CTypography variant="h4" fontWeight='bold' sx={{ mt: 2 }}>2014</CTypography>
                                    </Slide>
                                </Stack>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Card sx={{ borderRadius: '0% 10% 0% 10%' }} align='center'>

                            <CardMedia sx={{ height: 100, backgroundColor: '#DFF6FF' }} >
                                <Stack alignItems="center" sx={{ height: "100%" }} justifyContent="center" >
                                    <CImgStyle src={Clients} alt=" " />
                                </Stack>
                            </CardMedia>
                            <CardContent>
                                <Stack direction="column" alignItems="center" justifyContent="center" >
                                    <CTypography variant="h6" >Clients</CTypography>
                                    <Slide direction='up' >
                                        <CTypography variant="h4" fontWeight='bold' sx={{ mt: 2 }}>100+</CTypography>
                                    </Slide>
                                </Stack>
                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid item xs={6} md={3}>

                        <Card sx={{ borderRadius: '10% 0 10% 0' }} align='center'>
                            <CardMedia sx={{ height: 100, backgroundColor: '#DFF6FF' }}>
                                <Stack alignItems="center" sx={{ height: "100%" }} justifyContent="center" >
                                    <CImgStyle src={Project} alt=" " />
                                </Stack>

                            </CardMedia>
                            <CardContent>
                                <Stack direction="column" alignItems="center" justifyContent="center">
                                    <CTypography variant="h6">Projects completed</CTypography>
                                    <Slide direction='up' >
                                        <CTypography variant="h4" fontWeight='bold' sx={{ mt: 2 }}>1000+</CTypography>
                                    </Slide>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6} md={3}>

                        <Card sx={{ borderRadius: '0% 10% 0% 10%' }} align='center'>
                            <CardMedia sx={{ height: 100, backgroundColor: '#DFF6FF', }}>
                                <Stack alignItems="center" sx={{ height: "100%" }} justifyContent="center" >
                                    <CImgStyle src={Employees} alt=" " />
                                </Stack>
                            </CardMedia>
                            <CardContent>
                                <Stack direction="column" alignItems="center" justifyContent="center">
                                    <CTypography variant="h6">No. of Employees</CTypography>
                                    <Slide direction='up' >
                                        <CTypography variant="h4" fontWeight='bold' sx={{ mt: 2 }}>30+</CTypography>
                                    </Slide>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </GridItemStyle>


            <GridItemStyle item xs={12}>
                <section id="about-us">
                    <Grid container justifyContent="center">
                        <Grid item xs={6}>
                            <Stack
                                alignItems="center"
                                direction={{ xs: 'column', sm: 'row' }}
                                divider={<Divider orientation='vertical' sx={{ borderWidth: 1, borderRadius: 5, borderColor: 'primary.main' }} flexItem />}
                                justifyContent="center"
                                spacing={2}>
                                <CTypography variant="h6" sx={{ fontWeight: 'bold' }} color="textPrimary">Best solutions <br />For your company</CTypography>
                                <CTypography variant="subtitle1" color="textPrimary">We are a prominent IT firm with years of experience and knowledge. We are not just an IT company. At Argon IT Services LLP, we are a family. A family of over 50 people with years of experience in the field.
                                </CTypography>
                            </Stack>
                        </Grid>
                    </Grid>
                </section>
            </GridItemStyle>

            <GridItemStyle xs={12}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={5} sx={{ p: 3 }}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ p: 3 }}>
                                    <Stack direction="column" alignItems='flex-start' spacing={2}>
                                        <FontAwesomeIcon size='3x' icon={faBullseye} style={{ color: '#FC4F4F' }} />
                                        <CTypography variant='h6' sx={{ fontWeight: 'bold' }}>Mission</CTypography>
                                        <CTypography variant='body1'> Our mission is to deliver best solution in given time frame and to be the first choice for optimizing and achieving operational excellence through IT enabled services.</CTypography>
                                    </Stack>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ p: 3 }}>
                                    <Stack direction="column" alignItems='flex-start' spacing={2}>
                                        <FontAwesomeIcon size='3x' icon={faArrowsToEye} style={{ color: '#91C483' }} />
                                        <CTypography variant='h6' sx={{ fontWeight: 'bold' }}>Vision</CTypography>
                                        <CTypography variant='body1'> Our vision is to assure the highest quality service,client satisfaction, timely delivery of solutions by considering every client as a partner in our mutual success.</CTypography>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </GridItemStyle>
            <GridItemStyle xs={12}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={8} sx={{ p: 2 }}>
                        <CTypography variant="subtitle1" align='center' color="textPrimary">Our motto is generating Ideas and give quality solutions to our clients. At Argon IT Services LLP we provide highly secured, high quality services at affordable cost. With the help of strong leadership our people work with passion and are committed to on-going product improvement and leading technological development providing long-term customer relationships.</CTypography>
                    </Grid>
                </Grid>
            </GridItemStyle>

            <GridItemStyle item xs={12} >
                <section id="service">
                    <Grid container spacing={0} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                <CTypography variant="h3" fontSize={[30, 48]} fontWeight='bold'>Service We Provide</CTypography>
                                <img style={{ width: "80%" }} src={Line} alt=" " />
                                {/* <CTypography variant="h3" fontSize={[30, 48]} color='primary' fontWeight='bold' ></CTypography> */}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>

                            {/* <Divider sx={{ borderTop: 6, borderRadius: 5, borderColor: 'primary.main' }} /> */}
                        </Grid>
                    </Grid>
                </section>
            </GridItemStyle>

            <GridItemStyle item xs={12} >

                <Grid container alignItems="center" backgroundColor="#f0f0f0"
                    sx={{ p: [3, 3, 5], minHeight: '70vh' }}>
                    <Grid item xs={12} md={6}>
                        <Slide direction='left' triggerOnce>
                            <ImgStyle src={MacbookImage} alt="" />
                        </Slide>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Container>
                            <Stack direction="column" sx={{ p: 3, ml: 3 }} >
                                <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
                                    <CTypography variant="h4" align='left' color="textPrimary" sx={{ mb: 1, fontWeight: 'bold' }}>Website Development</CTypography>
                                    <Divider sx={{ borderTop: 1, borderRadius: 1, width: "20%", borderColor: '#512D6D' }} />
                                </Stack>
                                <CTypography variant="body1" align='justify' color="textSecondary" sx={{ mb: 1 }} >
                                    There's a reason why the best firms in the world choose us for <br />their web development requirements! We go above and above <br />to guarantee that we keep up with quickly changing web <br />technology.<br /><br />
                                    Our web application development team is well- versed in the <br />newest web technologies and employs this knowledge to create <br />high-performance online solution that propel your company<br /> to new heights.
                                </CTypography>
                                <Grid container alignItems="center" justifyContent="center" spacing={[0.5, 3]}>

                                    <Grid item>
                                        <img src={A} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faAngular} /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={RE} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faReact} /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={PHP} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faPhp} /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={NOTE} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faVuejs} /> */}
                                    </Grid>

                                </Grid>
                            </Stack>
                        </Container>
                    </Grid>
                </Grid>
            </GridItemStyle>


            <GridItemStyle item xs={12}>
                <Grid container alignItems="center" sx={{ p: [3, 3, 5], minHeight: '70vh' }}>
                    <Grid item xs={12} md={6}>
                        <Container >
                            <Stack direction="column" sx={{ p: 3, ml: 3 }}>
                                <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
                                    <CTypography variant="h4" align='left' color="textPrimary" sx={{ mb: 1, fontWeight: 'bold' }}> Application Development</CTypography>
                                    <Divider sx={{ borderTop: 1, borderRadius: 1, width: "20%", borderColor: '#368B85' }} />
                                </Stack>
                                <CTypography variant="body1" align='justify' color="textSecondary" sx={{ mb: 1 }} >
                                    Developing mobile apps over the years and collaborating on <br />several different projects that fulfills your needs, we've gained <br />profound expertise in this domain.<br /><br />
                                    Our work of successfully launched apps are notable in the <br />projects delivered to thousands of our clients globally. We also <br />augment your current team for a finite period.
                                </CTypography>
                                <Grid container alignItems="center" justifyContent="center" spacing={[0.5, 3]}>
                                    <Grid item>
                                        <img src={IOS} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faAndroid} /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={Android} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faApple} /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={Flutter} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faApple} /> */}
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Slide direction='right' triggerOnce>
                            <ImgStyle src={IPhoneImage} alt="" />
                        </Slide>
                    </Grid>
                </Grid>
            </GridItemStyle>
            <GridItemStyle item xs={12}>
                <Grid container alignItems="center" backgroundColor="#f0f0f0" sx={{ p: [3, 3, 5], minHeight: '70vh' }}>
                    <Grid item xs={12} md={6}>
                        <Slide direction='left' triggerOnce>
                            <ImgStyle src={GameImage} alt="" />
                        </Slide>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Container >
                            <Stack direction="column" sx={{ p: 3, ml: 3 }} >
                                <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
                                    <CTypography variant="h4" align='left' color="textPrimary" sx={{ mb: 1, fontWeight: 'bold' }}> Game Development</CTypography>
                                    <Divider sx={{ borderTop: 1, borderRadius: 1, width: "20%", borderColor: '#FFAA4C' }} />
                                </Stack>
                                <CTypography variant="body1" align='justify' color="textSecondary" sx={{ mb: 1 }} >
                                    The most entertaining we want the game to be, the more <br />complex it's coding can be. But don't worry! Our game <br />developers are proficient enough to deliver you with a <br />game that is not only fun to the users, but also gives an<br /> amazing gaming experience.<br /><br />
                                    We take care of all the factors it takes to make a game <br />creative, smooth and attractive enough for you to get a <br />big fat number of downloads. We are experts in Hyper<br /> Casual Game Development.
                                </CTypography>
                                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                                    <Grid item>
                                        <img src={Unty} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <FontAwesomeIcon size='2x' icon={faUnity} /> */}
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Container>
                    </Grid>
                </Grid>
            </GridItemStyle>
            <GridItemStyle item xs={12}>
                <Grid container alignItems="center" sx={{ p: [3, 3, 5], minHeight: '70vh' }}>
                    <Grid item xs={12} md={6}>
                        <Container >
                            <Stack direction="column" sx={{ p: 3, ml: 3 }}>
                                <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
                                    <CTypography variant="h4" align='left' color="textPrimary" sx={{ mb: 1, fontWeight: 'bold' }}> UI/UX design</CTypography>
                                    <Divider sx={{ borderTop: 1, borderRadius: 1, width: "20%", borderColor: '#0CECDD' }} />
                                </Stack>
                                <CTypography variant="body1" align='justify' color="textSecondary" sx={{ mb: 1 }} >
                                    To develop better IT products, we dedicate our experts to design the <br />most interactive & effective UI for a better user experience.<br /><br />
                                    A website is like a store. It will only work for you when your visitors <br />can find things easily. They should not be troubled with finding the<br /> information they seek. Proper UI/UX ensures that your visitors find <br />what they need in the least possible item.
                                </CTypography>
                                <Grid container alignItems="center" justifyContent='center' spacing={[0.5, 3]} >
                                    <Grid item>
                                        <img src={XD} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <CLogoStyle src={AdobeXd} alt="" /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={AI} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <CLogoStyle src={AdobePhotoshop} alt="" /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={PS} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <CLogoStyle src={AdobeIllustrator} alt="" /> */}
                                    </Grid>
                                    <Grid item>
                                        <img src={Fig} style={{ width: 56, height: 56 }} alt=" " />
                                        {/* <CLogoStyle src={Figma} alt="" /> */}
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Slide direction='right' triggerOnce>
                            <ImgStyle src={UiImage} alt="" />
                        </Slide>
                    </Grid>
                </Grid>
            </GridItemStyle>


            {/* <GridItemStyle item xs={12}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" spacing={1}>
                            <CTypography variant="h3" fontWeight='bold' fontSize={[30, 48]}>Our</CTypography>
                            <CTypography variant="h3" color='primary' fontSize={[30, 48]} fontWeight='bold' >Leaders</CTypography>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider sx={{ borderTop: 6, borderRadius: 5, borderColor: 'primary.main' }} />
                    </Grid>
                </Grid>
            </GridItemStyle> */}
            {/* <GridItemStyle item xs={12}>
                <Grid container alignItems="center" spacing={3} justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Stack direction="column" alignItems="center" justifyContent="center">
                            <AvatarStyle src={AvatarImg} alt="" />
                            <CTypography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }} color="textPrimary">Mr. Jatin Lakhani</CTypography>
                            <CTypography variant="body1" color="textSecondary">Founder</CTypography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack direction="column" alignItems="center" justifyContent="center">
                            <AvatarStyle src={AvatarImg} alt="" />
                            <CTypography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }} color="textPrimary">Mr. Hardik Savaliya</CTypography>
                            <CTypography variant="body1" color="textSecondary">Founder</CTypography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack direction="column" alignItems="center" justifyContent="center">
                            <AvatarStyle src={AvatarImg} alt="" />
                            <CTypography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }} color="textPrimary">Mr. Jignesh Bhalala</CTypography>
                            <CTypography variant="body1" color="textSecondary">Founder</CTypography>
                        </Stack>
                    </Grid>
                </Grid>
            </GridItemStyle> */}
            <MapBgStyle item xs={12} sx={{ overflow: 'hidden', mt: 5 }}>
                <section id="contact-us">
                    <Grid container sx={{ backgroundColor: 'rgba(0,0,0,0.4)' }} justifyContent='flex-end' alignItems="center">
                        <Grid item xs={12} md={6} lg={4} sx={{ m: 3 }}>
                            <ContactUsCard />
                        </Grid>
                    </Grid>
                </section>
            </MapBgStyle>
        </Grid >


    );
}

export default Home;