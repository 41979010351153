import LoadingButton from '@mui/lab/LoadingButton';
import { Card, Grid, styled, TextField } from '@mui/material';
import { Parse } from 'parse';
import React from 'react';
import { validateEmail } from '../utills/Validation';
import { ContactTable } from './../database/ContactTable';
import { validateName } from './../utills/Validation';
import CTypography from './custom/CTypography';
import CustomSnack from './custom/customSnack';

const GridItemStyle = styled(Grid)(({ theme }) => ({
    marginTop: 12,
    marginBottom: 12
}));

const ContactUsCard = () => {

    const [name, setName] = React.useState("")
    const [helperName, setHelperName] = React.useState("")
    const [errorName, setErrorName] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [helperEmail, setHelperEmail] = React.useState("")
    const [errorEmail, setErrorEmail] = React.useState(false)

    const [subject, setSubject] = React.useState("")
    const [message, setMessage] = React.useState("")

    const [snackContent, setSnackContent] = React.useState();
    const [variant, setVariant] = React.useState("error");

    const [isLoading, setIsLoading] = React.useState(false);


    const handleOnChange = (e) => {
        e.preventDefault()
        if (e.target.id === "tf-name") {
            setName(e.target.value)
            setErrorName(false)
            setHelperName("")
        } else if (e.target.id === "tf-email") {
            setEmail(e.target.value)
            setErrorEmail(false)
            setHelperEmail("")
        } else if (e.target.id === "tf-subject") {
            setSubject(e.target.value)
        } else if (e.target.id === "tf-message") {
            setMessage(e.target.value)
        }

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        console.log(1)

        if (!validateEmail(email)) {
            setHelperEmail("Invalid email address");
            setErrorEmail(true);
            return
        }
        if (!validateName(name)) {
            setHelperName("Minimum two letter required");
            setErrorName(true);
            return
        }

        const obj = new Parse.Object(ContactTable.TABLE_NAME);
        obj.set(ContactTable.NAME, name);
        obj.set(ContactTable.EMAIL_ID, email);
        obj.set(ContactTable.SUBJECT, subject);
        obj.set(ContactTable.MESSAGE, message);

        setIsLoading(true)

        Parse.Object.saveAll(obj).then(res => {
            setIsLoading(false)
            setSnackContent("We have received your request. We will contact you shortly.");
            setVariant("success")

            //history.push(Links.home)
            setName("")
            setEmail("")
            setSubject("")
            setMessage("")

        })
    }



    return (
        <Card sx={{ p: 5 }}>
            <CustomSnack
                open={Boolean(snackContent)}
                onClose={() => { setSnackContent(null) }}
                message={snackContent}
                variant={variant} />
            <form onSubmit={handleOnSubmit}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <CTypography align='center' variant="h6" fontWeight='bold'>Get in Touch</CTypography>
                    </Grid>
                    <GridItemStyle item xs={12} >
                        <TextField
                            id="tf-name"
                            label="Name"
                            placeholder='Enter your name'
                            value={name}
                            helperText={helperName}
                            error={errorName}
                            size='small'
                            fullWidth
                            onChange={handleOnChange}
                        />
                    </GridItemStyle>
                    <GridItemStyle item xs={12}>
                        <TextField
                            id="tf-email"
                            label="Email"
                            placeholder='Enter your email address'
                            value={email}
                            helperText={helperEmail}
                            error={errorEmail}
                            size='small'
                            fullWidth
                            onChange={handleOnChange}
                        />
                    </GridItemStyle>
                    <GridItemStyle item xs={12}>
                        <TextField
                            id="tf-subject"
                            label="Subject"
                            placeholder='Subject'
                            value={subject}
                            size="small"
                            fullWidth
                            onChange={handleOnChange}
                        />
                    </GridItemStyle>
                    <GridItemStyle item xs={12}>
                        <TextField
                            id="tf-message"
                            label="Message"
                            placeholder='Message'
                            value={message}
                            onChange={handleOnChange}
                            multiline
                            rows={3}
                            fullWidth
                            size='small'
                        />
                    </GridItemStyle>
                    <Grid item style={{ marginTop: 12 }}>
                        <LoadingButton
                            sx={{ px: { xs: 2, md: 5 }, py: { xs: 0.5, md: 1 }, borderRadius: 999, color: 'white', fontWeight: 'bold' }}
                            loading={isLoading}
                            color='primary' variant="contained" type="submit">Submit</LoadingButton>
                    </Grid>

                </Grid>
            </form>
        </Card>
    );
}

export default ContactUsCard;