import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';


const CTypographyStyle = styled(Typography)(({ theme }) => ({

    fontFamily: 'Lato-Regular',
   
}));
const CTypography = (props) => {
    return (
        <CTypographyStyle {...props}>
            {props.children}
        </CTypographyStyle>
    )
}

export default CTypography