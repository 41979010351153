import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckCircle, Error, Info, Close, Warning } from '@mui/icons-material';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles'

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.secondary.main,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    }, margin: {
        margin: theme.spacing(1),
    },
}));

export default function CustomSnack(props) {
    const classes = useStyles1();
    const { className, message, variant } = props;
    const Icon = variantIcon[variant];

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={props.onClose}
        >
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={props.onClose}>
                        <Close className={classes.icon} />
                    </IconButton>,
                ]} />
        </Snackbar>

    );
}

CustomSnack.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}