import React from 'react';
import Navbar from './components/Navbar';
import { Route, Switch } from 'react-router-dom';
import { Links } from './utills/Links';
import Home from './components/Home';
import Career from './components/Career';
import { Router } from 'react-router-dom';
import history from './history'
import { connectToServer } from './utills/ParseServer';
import Footer from './components/Footer';

function App() {
  React.useEffect(() => {
    connectToServer()
  }, [])
  return (
    < Router history={history} >
      <Navbar />
      <div style={{ marginTop: 64, marginBottom: 64 }}>
        <Switch>
          <Route path={Links.career} component={Career} />
          <Route path={Links.home} component={Home} />
        </Switch>
      </div>
      <Footer />
    </Router >

  );
}

export default App;
