export const validateMobile = (mobile) => {
    const regmm = '^[0-9]{10}$';
    const regmob = new RegExp(regmm);
    if (!mobile || mobile.length < 10 || !regmob.test(mobile)) return false
    return true
}

export const validateEmail = (email) => {
    if (!email || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return false
    return true
}

export const validateName = (name) => {
    if (!name || name.length < 2 || !name.match("^[a-zA-Z0-9.&#$?*%_!-@ ]{2,}$")) return false;
    return true;
}

export const validateLastName = (name) => {
    if (!name || name.length < 2 || !name.match("^[a-zA-Z0-9.&#$?*%_!-@ ]{2,}$")) return false;
    return true;
}