import { Button } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';

const CButton = (props) => {

    const CSecButtonStyle = styled(Button)(({ theme }) => ({
        borderRadius: '999px',
        backgroundColor: props.color === 'primary'? theme.palette.primary.main : theme.palette.secondary.main,
        color:'#fff',
        '&:hover':{
            backgroundColor: props.color === 'primary'? theme.palette.primary.main : theme.palette.secondary.main,
            color:'#fff'
        },
        '&:focus':{
            backgroundColor: props.color === 'primary'? theme.palette.primary.main : theme.palette.secondary.main,
            color:'#fff'
        },
        paddingLeft:24,
        paddingRight:24
    }));

    return (
        <CSecButtonStyle {...props}>
            {props.children}
        </CSecButtonStyle>
    )
}

export default CButton